<template>
  <section>
    <template v-if="dataReady">

      <section class="build-notice">
        <p class="build-notice__warning">If changes are made in this module you will need to trigger a homepage build.</p>
      </section>

      <section class="section" v-if="showNewsPromo">
        <h2 class="section__subheading subheading">News Promo</h2>
        <v-text-field v-model="homepageData.newsPromoURL" solo flat label="News Promo URL" @change="updateData()"/>

        <ImageUploader :title="'News Promo Image'" :widthOptionEnabled="false" :mediaLibraryID="homepageData.newsPromoImage" @update="updateImage($event, 'newsPromoImage')"/>
      </section>

      <custom-field-builder v-if="customHomepageData" :buildData="customHomepageData.default.data" :currentData="homepageData.customFields ? homepageData.customFields : {}" @update="updateCustomData($event)" />

      <template v-if="showPlayerHighlight">
        <section class="section">
          <h2 class="section__subheading subheading">Highlighted player</h2>
          <span class="row">
            <div class="item">
              <label class="label">Player Slug</label>
              <p><i>This should only contain the slug of the player page EG <b>ronaldo</b></i></p>
              <v-text-field v-model="homepageData.highlightedPlayerID" solo flat label="Player Slug"/>
            </div>
            <div class="item">
              <label class="label">Player Button Style</label>
              <v-select class="select"
                id="buttonType"
                :items="['', 'primary-light', 'primary-dark', 'ghost-light', 'ghost-dark', 'label-light', 'label-dark']"
                v-model="homepageData.playerButtonType"
                solo
                flat
              ></v-select>
            </div>
          </span>
          <v-text-field v-model="homepageData.highlightedPlayerName" solo flat label="Player Name"/>
          <v-text-field v-model="homepageData.highlightedPlayerStoreLink" solo flat label="Shirt URL"/>
          <v-text-field v-model="homepageData.highlightedPlayerOverlayText" solo flat label="Overlay text"/>

          <ImageUploader :title="'Image for large screens'" :widthOptionEnabled="false" :mediaLibraryID="homepageData.highlightedPlayerImage" @update="updateImage($event, 'highlightedPlayerImage')"/>

          <ImageUploader :title="'Image for small screens'" :widthOptionEnabled="false" :mediaLibraryID="homepageData.highlightedPlayerImageSmall" @update="updateImage($event, 'highlightedPlayerImageSmall')"/>

        </section>
      </template>

      <StickyFooter :errorMessage="errorMessage" :status="status" @buttonClicked="saveData()" :allowPreview="false"  />
    </template>
  </section>
</template>

<script>
import axios from "axios"
import StickyFooter from "@/components/Library/StickyFooter"
import ImageUploader from "@/components/Library/ImageUploader"
import CustomFieldBuilder from "@/components/Library/CustomFieldBuilder"

export default {
  name: "HomepageEditor",

  components: {
    StickyFooter,
    ImageUploader,
    CustomFieldBuilder
  },

  data:() => ({
    api: `${process.env.VUE_APP_BASEURL}/homepage`,
    customHomepageData: null,
    status: "saved",
    errorMessage: "",
    dataReady: false,
    homepageData: {
      customFields: null,
      newsPromoImage: "",
      newsPromoURL: "",
      highlightedPlayerID: "",
      highlightedPlayerImage: "",
      highlightedPlayerImageSmall: "",
      highlightedPlayerStoreLink: "",
      highlightedPlayerName: "",
      highlightedPlayerOverlayText: ""
    },
    betaFeatures: null,
    modules: null,
    showNewsPromo: false,
    showPlayerHighlight: false
  }),

  created() {
    this.betaFeatures = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/beta.js")
    this.customHomepageData = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/homepage.js")
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  },

  mounted() {
    this.fetchData()

    if (this.modules.default.HomepageNewsPromo) {
      this.showNewsPromo = true
    } else {
      this.showNewsPromo = false
    }

    if (this.modules.default.HomepagePlayerHighlight) {
      this.showPlayerHighlight = true
    } else {
      this.showPlayerHighlight = false
    }
  },

  methods: {
    updateCustomData(payload) {
      this.homepageData.customFields = payload

      this.updateData()
    },

    updateData() {
      this.status = "needsSaving";
    },

    fetchData() {
      console.log("Fetch homepage data")

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(this.api, { "headers": { "Authorization": jwt }})
          .then(response => {
            console.log("homepage response: ", response);
            if (response.data.homepage) {
              this.homepageData = response.data.homepage
            } else {
              this.homepageData = {}
            }
            this.dataReady = true
          })
          .catch(e => {
            console.log("Unable to load homepage:", e);
          })
      })
    },

    saveData() {
      this.status = "saving";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}`,
          headers: { "Authorization": jwt },
          data: this.homepageData
        })
        .then(response => {
          console.log("Saved homepage:", response);
          this.$store.commit("completeLoading");
          this.status = "saved";
        })
        .catch(e => {
          console.error("Problem saving homepage", e);
          this.$store.commit("completeLoading");
          this.status = "needsSaving";
          this.errorMessage = "Problem saving homepage";
        });
      })
    },

    updateImage(payload, target) {
      console.log("updateImage(payload, target)", payload, target )
      this.homepageData[target] = payload.mediaLibraryID

      this.updateData()
    }
  }
}
</script>

<style scoped lang="scss">
  .section {
    padding: 20px;
    margin: 0 0 40px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;

    &__subheading {
      margin-top: 0;
    }
  }

  .row {
    display: flex;
  }

  .item:first-child {
    flex-grow: 1;
  }

  .item:last-child {
    margin-left: 24px;
  }

  .select {
    max-width: 180px;
  }

  .build-notice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    background-color: #f4f6d2;
    padding: 8px 16px;
    border: 1px solid #d6d8b0;
    border-radius: 8px;

    &__warning {
      margin-bottom: 0;
    }
  }
</style>
