<template>
  <section>

    <v-fade-transition mode="out-in">
      <div v-if="authorised === true">
        <router-view></router-view>
      </div>
      <div v-else>
        <Unauthorised></Unauthorised>
      </div>
    </v-fade-transition>

  </section>
</template>

<script>
import Unauthorised from "@/components/Library/Unauthorised"

export default {
  name: "SiteConfig",
  components: {
    Unauthorised
  },
  data: () => {
    return {
      authorised: false
    }
  },
  mounted() {
    console.log("SiteConfig mounted");
    this.$getCurrentUserGroups().subscribe((userGroups) => {
      if(userGroups.includes("Administrator") || userGroups.includes("WebsiteManager")){
        this.authorised = true
      } else {
        this.authorised = false
      }
    }, 
    (err)=> {
       this.authorised = false
       console.log(err)
    });
  }
}
</script>
