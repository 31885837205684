export default {
  data: [
    {
      sectionName: "Homepage promo - left",
      sectionDesc: "This is for the left hand side (top on smaller screens) promotional area on the homepage",
      model: "promo_left",
      fields: [
        {
          label: "Title",
          model: "promo_left_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_left_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_left_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_left_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_left_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - right",
      sectionDesc: "This is for the right hand side (bottom on smaller screens) promotional area on the homepage",
      model: "promo_right",
      fields: [
        {
          label: "Title",
          model: "promo_right_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_right_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_right_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_right_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_right_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homeapage promo - large",
      sectionDesc: "This is for the full width promo on the homepage.",
      model: "promo_large",
      fields: [
        {
          label: "Title",
          model: "promo_large_promoTitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_large_description",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_large_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_large_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image (This will be the image that sits on the left side of the promo.)",
          model: "promo_large_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Advert - Above shop",
      sectionDesc: "",
      model: "aboveShopAdvert",
      fields: [
        {
          label: "Enabled",
          model: "aboveShopAdvert_enabled",
          type: "Checkbox"
        },
        {
          label: "Campaign Ref",
          model: "aboveShopAdvert_campaignRef",
          type: "Text"
        },
        {
          label: "Upload Desktop Image",
          model: "aboveShopAdvert_desktopImage",
          type: "Image"
        },
        {
          label: "Upload Mobile Image",
          model: "aboveShopAdvert_mobileImage",
          type: "Image"
        },
        {
          label: "Destination URL",
          model: "aboveShopAdvert_link",
          type: "Text"
        },
        {
          label: "Open in New Tab?",
          model: "aboveShopAdvert_newTab",
          type: "Checkbox"
        }
      ]
    },
    {
      sectionName: "Player Stats Profile",
      sectionDesc: "This defines the featured player used for the homepage top stats block for the respective teams. Please use a player ID. Player Spotlight uses the Web Profile Hero Image set on \"Team Management > Squad Management.\"",
      model: "highlighted_players",
      fields: [
        {
          label: "Show Men's team featured player and Hide stats",
          model: "show_players_men",
          type: "Checkbox"
        },
        {
          label: "Men team - Player ID",
          model: "highlighted_players_men",
          type: "Text"
        },
        {
          label: "Women team - Player ID",
          model: "highlighted_players_women",
          type: "Text"
        },
        {
          label: "Under 21 team - Player ID",
          model: "highlighted_players_u21",
          type: "Text"
        },
        {
          label: "Under 18 team - Player ID",
          model: "highlighted_players_u18",
          type: "Text"
        },
      ]
    },
    {
      sectionName: "Women's League Table Data",
      sectionDesc: "This defines stats data for the Women's League Table.",
      model: "womens_league_table",
      fields: [
        {
          label: "Matches played",
          model: "played",
          type: "Text"
        },
        {
          label: "Wins",
          model: "wins",
          type: "Text"
        },
        {
          label: "Draws",
          model: "draws",
          type: "Text"
        },
        {
          label: "Loses",
          model: "loses",
          type: "Text"
        },
        {
          label: "Goal Difference",
          model: "goal_difference",
          type: "Text"
        },
        {
          label: "Points",
          model: "points",
          type: "Text"
        },
        {
          label: "League Position",
          model: "league_position",
          type: "Text"
        },
      ]
    },
  ]
}
