<template>
  <section>

    <div class="build-manager" v-if="!modules.default.LFCExclusive">
      <p class="build-manager__title">Rebuild Homepage</p>
      <template  v-if="rebuildHomepageLock === false">
        <p>Clicking this button will rebuild the homepage of your site. This process should only take a few minutes.</p>
        <v-btn class="button" @click="rebuildHomepage()">Rebuild Homepage</v-btn>
      </template>
      <template v-else>
        <p>You recently rebuilt your homepage, this process can take a few minutes. Please refresh your customer facing website to see if your changes have come through.</p>
      </template>
    </div>

    <div class="build-manager">
      <p class="build-manager__title">Build Specific Paths</p>
      <template v-if="rebuildSpecificLock === false">
        <p>To rebuild a specific page enter the full slug (including any preceding "/") for the section that you want to rebuild.  If you want to rebuild multiple paths, please enter a comma seperated list.</p>

        <div v-if="!pageArray.length">
          <v-textarea
            outline
            flat
            rows="8"
            style="width: 400px;"
            v-model="pages">
          </v-textarea>
          <v-btn class="button" @click="buildArray(pages)">OK</v-btn>
        </div>
        <div v-else>
          <p>You are rebuilding the following pages</p>
          <ul>
            <li v-for="(page, index) in pageArray" :key="index">
              <strong>{{page}}</strong>
            </li>
          </ul>
          <v-btn class="button" v-if="pageArray.length > 1" @click="rebuildSpecific()">Rebuild these paths</v-btn>
          <v-btn class="button" v-else @click="rebuildSpecific()">Rebuild this path</v-btn>
          <v-btn class="error" @click="cancel()">Cancel</v-btn>
        </div>
      </template>

      <template v-else>
        <p>You recently sent a build up, this process can take a few minutes. Please refresh your customer facing website to see if your changes have come through.</p>
      </template>
    </div>

    <div class="build-manager">
      <p class="build-manager__title">Full Rebuild</p>
      <template  v-if="rebuildLock === false">
        <p>Clicking this button will rebuild the site's static content and refresh the cache. <span class="urgent">This process can take up to 10 minutes.</span><br /></p>
        <v-btn class="button" @click="rebuildSite()">Rebuild Site</v-btn>
      </template>
      <template v-else>
        <p>You recently rebuilt your site, this process can take up to 10 minutes. Please refresh your customer facing website to see if the cache is refreshed.</p>
      </template>
    </div>

  </section>
</template>

<script>
import axios from "axios"

export default {
  name: "BuildManager",

  props: {
    routeToBuild: {
      type: String,
      required: false
    }
  },

  data: () =>  ({
    buildApi: `${process.env.VUE_APP_PAGEAPI}`,
    pages: null,
    pageArray: [],
    rebuildLock: false,
    rebuildHomepageLock: false,
    rebuildSpecificLock: false,
    modules: null,
  }),

  created() {
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  },

  mounted() {
    this.checkRebuildLock()
    this.checkRebuildHomepageLock()
    this.checkRebuildSpecificLock()

    if (this.$route.query.routeToBuild) {
      this.pages = this.$route.query.routeToBuild
    }
  },

  methods: {
    checkRebuildLock(){
      var rebuildTimestamp = parseInt(localStorage.rebuildTimestamp)
      if(rebuildTimestamp > 0){
        var now = new Date()
        if ((now - rebuildTimestamp) > 300000) { //5 minutes
          this.rebuildLock = false
        } else {
          this.rebuildLock = true
        }
      }
    },

    checkRebuildHomepageLock(){
      var rebuildHomepageTimestamp = parseInt(localStorage.rebuildHomepageTimestamp)
      if(rebuildHomepageTimestamp > 0){
        var now = new Date()
        if ((now - rebuildHomepageTimestamp) > 300000) { //5 minutes
          this.rebuildHomepageLock = false
        } else {
          this.rebuildHomepageLock = true
        }
      }
    },

    checkRebuildSpecificLock(){
      var rebuildSpecificTimestamp = parseInt(localStorage.rebuildSpecificTimestamp)
      if(rebuildSpecificTimestamp > 0){
        var now = new Date()
        if ((now - rebuildSpecificTimestamp) > 300000) { //5 minutes
          this.rebuildSpecificLock = false
        } else {
          this.rebuildSpecificLock = true
        }
      }
    },

    rebuildSite(){
      console.log("rebuilding site")
      this.rebuildLock = true
      localStorage.rebuildTimestamp = new Date()
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/rebuild`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Site rebuilt", response)
          alert("Full Build Triggered Successfully")
        })
        .catch(e => {
          console.error("Problem rebuilding", e);
        });
      })
    },

    buildArray(pages) {
      this.pageArray = pages.split(",")
      console.log("array", this.pageArray)
    },

    rebuildSpecific() {
      this.$store.commit("startLoading");
      localStorage.rebuildSpecificTimestamp = new Date();
      this.rebuildSpecificLock = true;

      this.$gcPublish(this.pageArray).then((success) => {
        console.log("publish", success)
        this.$store.commit("completeLoading");
        alert("Page Build Triggered Successfully")

        this.pageArray = []
        this.pages = null
      })
      .catch((error) => {
        console.error("Specific Build Error", error)
        this.$store.commit("completeLoading");
      })
    },

    rebuildHomepage() {
      this.$store.commit("startLoading");
      localStorage.rebuildHomepageTimestamp = new Date();
      this.rebuildHomepageLock = true;

      this.$gcPublish(["/"]).then((success) => {
        console.log("publish homepage", success)
        this.$store.commit("completeLoading");
        alert("Homepage Build Triggered Successfully")
      })
      .catch((error) => {
        console.error("Homepage Build Error", error)
        this.$store.commit("completeLoading");
      })
    },

    cancel() {
      this.pages = null
      this.pageArray = []
    }
  }
}
</script>

<style scoped lang='scss'>

.build-manager {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 30px 0 0;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #67247c;
    border-radius: 4px;

    &__title {
      position: absolute;
      top: -15px;
      left: -1px;
      display: inline;
      margin: 0;
      padding: 1px 5px;
      color: #fff;
      font-weight: 500;
      background-color: #67247c;
      border-radius: 0 4px 0 0;
    }
  }

  .button {
    background-color: #67247c !important;
    color: #fff !important;
  }

  .error {
    background-color: #ff5252 !important;
  }

  .urgent {
    font-weight: 700;
    color: #ff5252 !important;
  }

</style>
