<template>
  <div>
    <template v-if="fathomNotFound">
      <p><i>No Fathom Credentials</i></p>
    </template>
    <template v-else>
      <p :class="invalid ? 'invalid' : ''"><i>{{validationMessage}}</i></p>
    </template>
  </div>
</template>

<script>
import axios from "axios"

export default {

  name: "FathomConfiguration",

  props: {
    fathomID: {
      type: String,
      required: false
    }
  },

  watch: { 
    fathomID: function() {
      this.getSite()
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_FATHOMAPI}`,
    site: `${process.env.VUE_APP_SITE_DOMAIN}`,
    fathomNotFound: null,
    siteDetails: null,
    validationMessage: null,
    invalid: null
  }),

  mounted() {
    if (this.fathomID) {
      this.getSite()
    } else {
      this.fathomNotFound = true
    }
  },

  methods: {
    getSite() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        if (this.fathomID && this.fathomID != "") {
          this.fathomNotFound = false
          axios
          .get(`${process.env.VUE_APP_FATHOMAPI}/fathom/?path=sites/${this.fathomID}`, { "headers": { "Authorization": jwt, "Accept": "application/json" }})
          .then(response => {
            this.siteDetails = response.data.data
            this.checkDetails()
          })
          .catch(e => {
            this.validationMessage = e
            this.invalid = true
          });
        } else {
          this.fathomNotFound = true
        }
  
      })
    },
    checkDetails() {
      if (this.siteDetails.name === this.site) {
        this.validationMessage = "Your site is connected to the correct fathom account"
        this.invalid = false
      } else {
        this.validationMessage = `These credentials are incorrect: They belong to ${this.siteDetails.name}`
        this.invalid = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.invalid {
  color: $errorRed;
}

</style>