<template>
  <div v-if="!configsLoaded">Loading</div>

  <form v-else>
    <section class="build-notice">
      <p class="build-notice__warning">
        If changes are made in this module you will need to trigger a full
        website build.
      </p>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Meta Info</h3>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="metaPageTitle">Meta title - Before</label>
          <v-text-field
            id="metaPageTitle"
            type="text"
            placeholder=""
            solo
            flat
            v-model="configSettings.metaPageTitleBefore"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >The Meta Title Before value is used to add a consistent element
              to the beginning of your meta titles. This is optional and can be
              previewed below. Social titles do not use this element.</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="metaPageTitle">Meta title - After</label>
          <v-text-field
            id="metaPageTitle"
            type="text"
            placeholder=""
            solo
            flat
            v-model="configSettings.metaPageTitleAfter"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >The Meta Title After value is used to add a consistent element to
              the end of your meta titles. This is optional and can be previewed
              below. Social titles do not use this element.</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <p>
            <i>Preview Meta Title:</i>
            <span class="render-note"
              >{{ configSettings.metaPageTitleBefore }}Page title{{
                configSettings.metaPageTitleAfter
              }}</span
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="metaPageTitle"
            >Default/Homepage Page Title</label
          >
          <v-text-field
            id="metaPageTitle"
            type="text"
            placeholder="Club Name"
            solo
            flat
            v-model="configSettings.metaPageTitle"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This is used on the homepage and as a fallback in case a page
              title is missing. This is used in combination with the meta title
              before and after values.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="metaPageDesc"
            >Default/Homepage Page Description</label
          >
          <v-text-field
            id="metaPageDesc"
            type="text"
            placeholder="The Official Website of Club Name: Enjoy Your Visit"
            solo
            flat
            v-model="configSettings.metaPageDesc"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This is used on the homepage and as a fallback in case a page
              description is missing.</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout v-if="!modules.default.LFCExclusive" row>
        <v-flex mr-5 class="half">
          <label class="label" for="metaPageTitle">News Page Title</label>
          <v-text-field
            id="metaPageTitle"
            type="text"
            placeholder="News"
            solo
            flat
            v-model="configSettings.newsPageTitle"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This is used on the news page. This is used in combination with
              the meta title before and after values.</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <label class="label" for="videoPageDesc">News Page Description</label>
          <v-text-field
            id="videoPageDesc"
            type="text"
            placeholder="Keep up to date with all the latest news"
            solo
            flat
            v-model="configSettings.newsPageDesc"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This is used on the news page.</i></p>
        </v-flex>
      </v-layout>

      <v-layout v-if="!modules.default.LFCExclusive" row>
        <v-flex mr-5 class="half">
          <label class="label" for="metaPageTitle">Video Page Title</label>
          <v-text-field
            id="metaPageTitle"
            type="text"
            placeholder="Videos"
            solo
            flat
            v-model="configSettings.videoPageTitle"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This is used on the video page. This is used in combination with
              the meta title before and after values.</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout v-if="!modules.default.LFCExclusive" row>
        <v-flex mr-5 class="half">
          <label class="label" for="videoPageDesc"
            >Video Page Description</label
          >
          <v-text-field
            id="videoPageDesc"
            type="text"
            placeholder="The Official Website of Club Name: Enjoy the Videos"
            solo
            flat
            v-model="configSettings.videoPageDesc"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This is used on the video page.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <section v-if="!modules.default.LFCExclusive" class="section-container">
      <h3 class="subheading mt-0">Social Accounts</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="facebook">Facebook</label>
          <v-text-field
            id="facebook"
            type="text"
            placeholder="Facebook"
            solo
            flat
            v-model="configSettings.facebook"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="twitter">X</label>
          <v-text-field
            id="twitter"
            type="text"
            placeholder="Twitter"
            solo
            flat
            v-model="configSettings.twitter"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="threads">Threads</label>
          <v-text-field
            id="threads"
            type="text"
            placeholder="Threads"
            solo
            flat
            v-model="configSettings.threads"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="instagram">Instagram</label>
          <v-text-field
            id="instagram"
            type="text"
            placeholder="Instagram"
            solo
            flat
            v-model="configSettings.instagram"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="snapchat">Snapchat</label>
          <v-text-field
            id="snapchat"
            type="text"
            placeholder="Snapchat"
            solo
            flat
            v-model="configSettings.snapchat"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="tiktok">TikTok</label>
          <v-text-field
            id="tiktok"
            type="text"
            placeholder="TikTok"
            solo
            flat
            v-model="configSettings.tiktok"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="instagram">YouTube</label>
          <v-text-field
            id="youtube"
            type="text"
            placeholder="youtube"
            solo
            flat
            v-model="configSettings.youtube"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="weibo">Weibo</label>
          <v-text-field
            id="weibo"
            type="text"
            placeholder="weibo"
            solo
            flat
            v-model="configSettings.weibo"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="linkedin">LinkedIn</label>
          <v-text-field
            id="linkedIn"
            type="text"
            placeholder="linkedin"
            solo
            flat
            v-model="configSettings.linkedin"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Default Images</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'404 Background'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.errorPageImage"
            @update="updateImage($event, 'errorPageImage'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >For use on 404 page when users visit a missing page. Recommended
              size - 1920 x 1080 (16:9) Image Ratio</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Match Centre (HOME)'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.matchCentreHomeBg"
            @update="updateImage($event, 'matchCentreHomeBg'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This will be displayed if you do not upload a custom image to the
              relevant fixture.Recommended size - 1920 x 1080 (16:9) Image
              Ratio</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Match Centre (AWAY)'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.matchCentreAwayBg"
            @update="updateImage($event, 'matchCentreAwayBg'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This will be displayed if you do not upload a custom image to the
              relevant fixture.Recommended size - 1920 x 1080 (16:9) Image
              Ratio</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Locked Video Placeholder'"
            :preventDeletion="true"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.lockedVideoPlaceholder"
            @update="
              updateImage($event, 'lockedVideoPlaceholder'), updateData()
            "
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Fallback image for locked videos. Recommended size - 1920 x 1080
              (16:9) Image Ratio</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Article Card Placeholder'"
            :preventDeletion="true"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.articleCardPlaceholder"
            @update="
              updateImage($event, 'articleCardPlaceholder'), updateData()
            "
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This is used as the fallback image for article listing cards,
              page listing cards, video cards etc. Recommended size - 800 x 450
              (16:9) Image Ratio</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout v-if="modules.default.LFCExclusive" row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'TV Schedule Placeholder'"
            :preventDeletion="true"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.tvSchedulePlaceholder"
            @update="updateImage($event, 'tvSchedulePlaceholder'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Fallback image for the TV schedule videos. Recommended size - 800
              x 450 (16:9) Image Ratio</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Featured Match'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.featuredUpcoming"
            @update="updateImage($event, 'featuredUpcoming'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >‘Used as background for featured fixture component on fixtures
              page. Recommended size - 1920 x 1080 (16:9) Image Ratio</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Featured Result'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.featuredResult"
            @update="updateImage($event, 'featuredResult'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Used as background for featured result component on results page.
              Recommended size - 1920 x 1080 (16:9) Image Ratio</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section v-if="!modules.default.LFCExclusive" class="section-container">
      <h3 class="subheading mt-0">Matchday Settings</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="takeoverType">Takeover Type</label>
          <v-select
            outline
            v-model="configSettings.matchdayTakeoverType"
            :items="takeoverOptions"
            @input="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <template v-if="configSettings.matchdayTakeoverType === 'matchday'">
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="takeoverType"
              >Matchday Takeover Style</label
            >
            <v-select
              outline
              v-model="configSettings.matchdayTakeoverStyle"
              :items="takeoverStyleOptions"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="takeoverType"
              >Matchday Takeover Mode</label
            >
            <v-select
              outline
              v-model="configSettings.matchdayTakeoverMode"
              :items="takeoverModeOptions"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="webTakeoverBackground"
              >Web Takeover Background</label
            >
            <ImageUploader
              mb-3
              :title="'Web Takeover Background'"
              :widthOptionEnabled="false"
              :mediaLibraryID="configSettings.webTakeoverBackground"
              @update="
                updateImage($event, 'webTakeoverBackground'), updateData()
              "
            />
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
      </template>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Player Page Settings</h3>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Player Profile Background Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.playerProfileBackground"
            @update="
              updateImage($event, 'playerProfileBackground'), updateData()
            "
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This will display as a background for the player hero.
              Recommended ratio - 5:1</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Player Profile Placeholder Image'"
            :preventDeletion="true"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.playerProfilePlaceholder"
            @update="
              updateImage($event, 'playerProfilePlaceholder'), updateData()
            "
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This should be an outline image of a player in case imagery is
              not available. Recommended size - 720 x 768px</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <label class="label" for="playerPageCareerHeading"
            >Player Page Career Title</label
          >
          <v-text-field
            id="playerPageCareerHeading"
            type="text"
            placeholder="Career History"
            solo
            flat
            v-model="configSettings.playerPageCareerHeading"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This will be used as the section title if you utilise the 'Career
              History' section of the player profile.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <label class="label" for="playerPageAwardsHeading"
            >Player Page Awards Title</label
          >
          <v-text-field
            id="playerPageAwardsHeading"
            type="text"
            placeholder="Awards"
            solo
            flat
            v-model="configSettings.playerPageAwardsHeading"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This will be used as the section title if you utilise the
              'Awards' section of the player profile.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Home Kit'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.homeKit"
            @update="updateImage($event, 'homeKit'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Home kit asset for the player retail widget. This should be a
              .png with transparency. Recommended size - 400 x 400</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Away Kit'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.awayKit"
            @update="updateImage($event, 'awayKit'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Away kit asset for the player retail widget. This should be a
              .png with transparency. Recommended size - 400 x 400</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout v-if="!modules.default.LFCExclusive" row class="">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Goalkeeper Kit'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.goalkeeperKit"
            @update="updateImage($event, 'goalkeeperKit'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Goalkeeper kit asset for the player retail widget. This should be
              a .png with transparency. Recommended size - 400 x 400</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout v-if="!modules.default.LFCExclusive" row class="">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Third Kit'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.thirdKit"
            @update="updateImage($event, 'thirdKit'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Third kit asset for the player retail widget. This should be
              a .png with transparency. Recommended size - 400 x 400</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Cookie Policy</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="cookieHeadingText"
            >Cookie Notice Heading</label
          >
          <v-text-field
            id="cookieHeadingText"
            type="text"
            placeholder="Cookie Notice"
            solo
            flat
            v-model="configSettings.cookieHeadingText"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="cookieBodyText"
            >Cookie Notice Information</label
          >
          <v-textarea
            id="cookieBodyText"
            placeholder="This website employs cookies to improve user experience. If you continue on this website you will be providing your consent to our use of all cookies. Click the link to find out more about the cookies. Please note, if you do turn non-essential cookies off, this will limit the service that we are able to provide to you and may affect your user experience."
            solo
            flat
            v-model="configSettings.cookieBodyText"
            @input="updateData()"
          >
          </v-textarea>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Accepts HTML</i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="cookieAcceptButtonText"
            >Acceptance Button Text</label
          >
          <v-text-field
            id="cookieAcceptButtonText"
            type="text"
            placeholder="Accept All Cookies"
            solo
            flat
            v-model="configSettings.cookieAcceptButtonText"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="cookieDeclineButtonText"
            >Decline Button Text</label
          >
          <v-text-field
            id="cookieDeclineButtonText"
            type="text"
            placeholder="Only Essential"
            solo
            flat
            v-model="configSettings.cookieDeclineButtonText"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="cookieSlug">Information Link</label>
          <v-text-field
            id="cookieSlug"
            type="text"
            placeholder="/cookie-policy"
            solo
            flat
            v-model="configSettings.cookieSlug"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Please add the path for your cookie policy page. For internal
              URLs you will only need to populate the slug. For external URLs
              you should use the whole web address.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="cookieInfoButtonText"
            >Information Link Text</label
          >
          <v-text-field
            id="cookieInfoButtonText"
            type="text"
            placeholder="Find Out More"
            solo
            flat
            v-model="configSettings.cookieInfoButtonText"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Please add the text you want to display on the button for the
              Information Link.</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section v-if="!modules.default.LFCExclusive" class="section-container">
      <h3 class="subheading mt-0">News Settings</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="newsPageHeading"
            >News Page Main Heading</label
          >
          <v-text-field
            id="newsPageHeading"
            type="text"
            placeholder="Featured"
            solo
            flat
            v-model="configSettings.newsPageHeading"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="newsPageSubHeading"
            >News Page Sub Heading</label
          >
          <v-text-field
            id="newsPageSubHeading"
            type="text"
            placeholder="All News"
            solo
            flat
            v-model="configSettings.newsPageSubHeading"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Stripe</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="successPage">Payment Success Page</label>
          <v-text-field
            id="successPage"
            type="text"
            placeholder="https://club.domain/pay/success"
            solo
            flat
            v-model="configSettings.successPage"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            Redirect this package on payment success.
            <i>This is a full URL including https.</i>
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="cancelPage"
            >Payment Cancellation Page</label
          >
          <v-text-field
            id="cancelPage"
            type="text"
            placeholder="https://club.domain/pay/cancel"
            solo
            flat
            v-model="configSettings.cancelPage"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            Redirect this package on payment cancel.
            <i>This is a full URL including https.</i>
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section v-if="!modules.default.LFCExclusive" class="section-container">
      <h3 class="subheading mt-0">Social Sharing</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="socialSharing.enabled"
            >Enable Social Sharing</label
          >
          <v-select
            outline
            v-model="configSettings.socialSharingEnabled"
            :items="trueFalseSelect"
            @input="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Social Sharing Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.socialSharingImage"
            @update="updateImage($event, 'socialSharingImage'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This image will show in the social media preview if no suitable
              image is found in the article. Recommended size - 1280 x 670px</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="socialSharing.enabled"
            >Enable X (former Twitter)</label
          >
          <v-select
            outline
            v-model="configSettings.socialSharingTwitterEnabled"
            :items="trueFalseSelect"
            @input="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="twitter.preText">X Pre-Text</label>
          <v-text-field
            id="twitter.preText"
            type="text"
            placeholder="Latest news: "
            solo
            flat
            v-model="configSettings.socialSharingTwitterPreText"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="socialSharing.enabled"
            >Enable Facebook</label
          >
          <v-select
            outline
            v-model="configSettings.socialSharingFacebookEnabled"
            :items="trueFalseSelect"
            @input="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="socialSharing.enabled"
            >Enable Copy Link</label
          >
          <v-select
            outline
            v-model="configSettings.socialSharingCopyLinkEnabled"
            :items="trueFalseSelect"
            @input="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="socialSharing.enabled">Enable Email</label>
          <v-select
            outline
            v-model="configSettings.socialSharingEmailEnabled"
            :items="trueFalseSelect"
            @input="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Website Styles</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="headerType">Header Type</label>
          <v-select
            ref="headerType"
            outline
            v-model="configSettings.websiteHeaderType"
            :items="headerSelect"
            @input="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>
              <b>Reveal - </b>Present at the top of every page, and a version of
              the main navigation reappears when scrolling back up the page.<br />
              <b>Fixed - </b>Always present at the top of the users viewport.<br />
              <b>Sticky - </b>An alternative (or the same) version of the
              navigation will appear at the top of the viewport after scrolling
              some way down the page. This will dissapear again before reaching
              the original navigation at the top of the page. Allows use of an
              alternative logo.<br />
            </i>
          </p>
          <p>
            <i
              >If you make changes to the originally selected navigation you may
              see unexpected results. To make a change you should raise a
              feature request via freshdesk.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="!modules.default.LFCExclusive"
        row
        class="section-divider"
      >
        <v-flex mr-5 class="half">
          <label class="label" for="globalNav">Show Global Nav</label>
          <v-select
            ref="globalNav"
            outline
            v-model="configSettings.websiteGlobalNav"
            :items="trueFalseSelect"
            @input="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >If you have chosen to have a global navigaiton section above your
              main header this can be used to show or hide it. Canges to the
              links in this navigation should be raised via freshdesk as a
              change request.</i
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Main Club Logo'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.clubLogo"
            @update="updateImage($event, 'clubLogo'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This should be a hi-res .png of your club crest.</i></p>
        </v-flex>
      </v-layout>
      <v-layout v-if="!modules.default.LFCExclusive" row>
        <v-flex mr-5 class="half">
          <ImageUploader
            mb-3
            :title="'Sticky Header Logo'"
            :widthOptionEnabled="false"
            :mediaLibraryID="configSettings.stickyHeaderLogo"
            @update="updateImage($event, 'stickyHeaderLogo'), updateData()"
          />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This may be used if you have an alternative logo style required
              for custom menu types.<br />Please raise a request with the
              UrbanZoo team if you require this.</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <section class="section-container">
      <h3 class="subheading mt-0">Copyright Notice</h3>
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="copyrightNotice"></label>
          <v-text-field
            id="copyrightNotice"
            type="text"
            placeholder="&copy;Copyright Club Name 1900 - 2000"
            solo
            flat
            v-model="configSettings.copyrightNotice"
            @input="updateData()"
          >
          </v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Accepts HTML</i></p>
        </v-flex>
      </v-layout>
    </section>

    <v-btn
      @click="toggleAdvanced()"
      color="accent"
      type="button"
      depressed
      class="advanced-button"
    >
      <span v-if="!toggled">Show Advanced Options</span>
      <span v-else>Hide Advanced Options</span>
    </v-btn>

    <template v-if="superUser && toggled">
      <section class="section-container">
        <h3 class="subheading mt-0">Single Sign On</h3>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="loginPortal">Login Portal URL</label>
            <v-text-field
              id="loginPortal"
              type="text"
              placeholder="https://login.domain.club.com"
              solo
              flat
              v-model="configSettings.loginPortal"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>No trailing slash</i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="registerUrl">Registration URL</label>
            <v-text-field
              id="registerUrl"
              type="text"
              placeholder="https://login.domain.club.com/register"
              solo
              flat
              v-model="configSettings.registerUrl"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="forgotPasswordUrl"
              >Forgot Password URL</label
            >
            <v-text-field
              id="forgotPasswordUrl"
              type="text"
              placeholder="https://forgot-password.domain.club.com"
              solo
              flat
              v-model="configSettings.forgotPasswordUrl"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="manageAccountUrl"
              >Account Management URL</label
            >
            <v-text-field
              id="manageAccountUrl"
              type="text"
              placeholder="https://my-account.domain.club.com"
              solo
              flat
              v-model="configSettings.manageAccountUrl"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="loginHeading">Login Heading</label>
            <v-text-field
              id="loginHeading"
              type="text"
              placeholder="Sign in to your club account"
              solo
              flat
              v-model="configSettings.loginHeading"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="loginBody">Login Copy</label>
            <v-textarea
              id="loginBody"
              type="text"
              placeholder="Live commentary and some videos are exclusive to subscribers - buy a pass after logging in to enjoy full access."
              solo
              flat
              v-model="configSettings.loginBody"
              @input="updateData()"
            >
            </v-textarea>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>Accepts HTML</i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="registerHeading"
              >Registration Heading</label
            >
            <v-text-field
              id="registerHeading"
              type="text"
              placeholder="Register for a free Club Account"
              solo
              flat
              v-model="configSettings.registerHeading"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="registerBody">Registration Copy</label>
            <v-textarea
              id="registerBody"
              type="text"
              placeholder="Sign-up to for the latest news, exclusive offers and exciting competitions."
              solo
              flat
              v-model="configSettings.registerBody"
              @input="updateData()"
            >
            </v-textarea>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>Accepts HTML</i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="accountHeading">Account Heading</label>
            <v-text-field
              id="accountHeading"
              type="text"
              placeholder="Your club account"
              solo
              flat
              v-model="configSettings.accountHeading"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="upgradeNoticeHeading"
              >Upgrade Notice Heading</label
            >
            <v-text-field
              id="upgradeNoticeHeading"
              type="text"
              placeholder="Club TV"
              solo
              flat
              v-model="configSettings.upgradeNoticeHeading"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="upgradeNoticeBody"
              >Upgrade Notice Copy</label
            >
            <v-text-field
              id="upgradeNoticeBody"
              type="text"
              placeholder="To stream live games you must be subscribed to a TV package"
              solo
              flat
              v-model="configSettings.upgradeNoticeBody"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="upgradeNoticeLearnMore"
              >Learn More Link</label
            >
            <v-text-field
              id="upgradeNoticeLearnMore"
              type="text"
              placeholder="/account/faqs"
              solo
              flat
              v-model="configSettings.upgradeNoticeLearnMore"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout v-if="!modules.default.LFCExclusive" row class="">
          <v-flex mr-5 class="half">
            <ImageUploader
              mb-3
              :title="'Upgrade Notice Promo Image'"
              :widthOptionEnabled="false"
              :mediaLibraryID="configSettings.upgradeNoticePromoImage"
              @update="
                updateImage($event, 'upgradeNoticePromoImage'), updateData()
              "
            />
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>Recommended size - 800 x 450 (16:9) Image Ratio</i></p>
          </v-flex>
        </v-layout>
      </section>

      <section class="section-container">
        <h3 class="subheading mt-0">Advanced Options</h3>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="googleTagManagerID"
              >Google Tag Manager ID</label
            >
            <v-text-field
              id="googleTagManagerID"
              type="text"
              placeholder="GTM-xxxxx"
              solo
              flat
              v-model="configSettings.googleTagManagerID"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="ecalLink">eCal Link</label>
            <v-text-field
              id="ecalLink"
              type="text"
              placeholder="https://sync.ecal.com/button/v1/ecal.html?apiKey="
              solo
              flat
              v-model="configSettings.ecalLink"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >If you provide an eCal link to your match calendar, a button
                for users to get all fixtures will be provided on the match
                page.</i
              >
            </p>
          </v-flex>
        </v-layout>
      </section>

      <section class="section-container">
        <h3 class="subheading mt-0">Club Configs</h3>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="defaultDateFormat"
              >Default Date Format</label
            >
            <v-text-field
              id="defaultDateFormat"
              type="text"
              placeholder="D MMM YYYY"
              solo
              flat
              v-model="configSettings.defaultDateFormat"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="matchDateFormat">Match Date Format</label>
            <v-text-field
              id="matchDateFormat"
              type="text"
              placeholder="ddd D MMM"
              solo
              flat
              v-model="configSettings.matchDateFormat"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="defaultAuthor">Default Author</label>
            <v-text-field
              id="defaultAuthor"
              type="text"
              placeholder="Urban Zoo FC"
              solo
              flat
              v-model="configSettings.defaultAuthor"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="shirtIconStyle">Shirt Icon Style</label>
            <v-select
              outline
              v-model="configSettings.shirtIconStyle"
              :items="[
                { text: 'Plain', value: 'plain' },
                { text: 'Hollow', value: 'hollow' },
                { text: 'Hoops', value: 'hoops' },
                { text: 'Stripes', value: 'stripes' },
              ]"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="socialSlogan"
              >Match Centre Hashtag Title</label
            >
            <v-text-field
              id="socialSlogan"
              type="text"
              placeholder="Get Involved On X"
              solo
              flat
              v-model="configSettings.socialSlogan"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >If you add a hashtag to fixtures, this is the section title.</i
              >
            </p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!modules.default.LFCExclusive"
          row
          class="section-divider"
        >
          <v-flex mr-5 class="half">
            <label class="label" for="showDefaultNewsIcon"
              >Show default news icon?</label
            >
            <v-select
              outline
              v-model="configSettings.showDefaultNewsIcon"
              :items="trueFalseSelect"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout v-if="!modules.default.LFCExclusive" row>
          <v-flex mr-5 class="half">
            <label class="label" for="showDefaultNewsIcon"
              >Signpost External URLs?</label
            >
            <v-select
              outline
              v-model="configSettings.signpostExternal"
              :items="trueFalseSelect"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
      </section>

      <section class="section-container">
        <h3 class="subheading mt-0">Developer Settings</h3>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="googleRecaptchaSiteKey"
              >Google ReCaptcha Site Key</label
            >
            <v-text-field
              id="googleRecaptchaSiteKey"
              type="text"
              placeholder=""
              solo
              flat
              v-model="configSettings.googleRecaptchaSiteKey"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="googleMapsAPIKey"
              >Google Maps API Key</label
            >
            <v-text-field
              id="googleMapsAPIKey"
              type="text"
              placeholder=""
              solo
              flat
              v-model="configSettings.googleMapsAPIKey"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="stripeAPIKey">Stripe API Key</label>
            <v-text-field
              id="stripeAPIKey"
              type="text"
              placeholder=""
              solo
              flat
              v-model="configSettings.stripeAPIKey"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="androidLink">Android Store Link</label>
            <v-text-field
              id="androidLink"
              type="text"
              placeholder=""
              solo
              flat
              v-model="configSettings.androidLink"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="iosLink">App Store Link</label>
            <v-text-field
              id="iosLink"
              type="text"
              placeholder=""
              solo
              flat
              v-model="configSettings.iosLink"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="sentryDSN">Sentry DSN</label>
            <v-text-field
              id="sentryDSN"
              type="text"
              placeholder=""
              solo
              flat
              v-model="configSettings.sentryDSN"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="sentryEnv">Sentry Env</label>
            <v-text-field
              id="sentryEnv"
              type="text"
              placeholder=""
              solo
              flat
              v-model="configSettings.sentryEnv"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="fathomID">Fathom ID</label>
            <v-text-field
              id="fathomID"
              type="text"
              placeholder=""
              solo
              flat
              v-model="configSettings.fathomID"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <fathom-configuration :fathomID.sync="configSettings.fathomID" />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="debugHelp">Show Debug Help</label>
            <v-select
              outline
              v-model="configSettings.debugHelp"
              :items="trueFalseSelect"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i></i></p>
          </v-flex>
        </v-layout>
      </section>

      <section v-if="modules.default.LFCExclusive" class="section-container">
        <h3 class="subheading mt-0">Sitewide Marketing Pop-up</h3>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupIsActive"
              >Marketing Pop-up Enabled</label
            >
            <v-select
              outline
              v-model="configSettings.marketingPopupIsActive"
              :items="[
                {
                  text: 'Yes, enable the marketing pop-up',
                  value: true,
                },
                {
                  text: 'No, disable the marketing pop-up',
                  value: false,
                },
              ]"
              item-value="value"
              item-text="text"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i>Enables the marketing pop-up that shows across the website</i>
            </p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupIsActive"
              >Video page Pop-up Enabled</label
            >
            <v-select
              outline
              v-model="configSettings.marketingPopupVideoPageIsActive"
              :items="[
                {
                  text: 'Yes, enable the video page pop-up',
                  value: true,
                },
                {
                  text: 'No, disable the video page pop-up',
                  value: false,
                },
              ]"
              item-value="value"
              item-text="text"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>Enables the marketing pop-up that shows on video pages</i></p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupDefaultMarketingPopup"
              >Default Pop-up</label
            >
            <v-select
              outline
              v-model="configSettings.marketingPopupDefaultMarketingPopup"
              :items="[
                { text: 'Registration', value: 'registration' },
                { text: 'Subscription', value: 'subscription' },
              ]"
              item-value="value"
              item-text="text"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>This is the promo that will show for logged out users</i></p>
          </v-flex>
        </v-layout>

        <h4 class="subheading mt-0">Registration Pop-up</h4>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="registerPromoTitle">Title</label>
            <v-text-field
              id="registerPromoTitle"
              type="text"
              placeholder="Join MYLFC"
              solo
              flat
              v-model="configSettings.marketingPopupRegisterPromoTitle"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half"> </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="registerPromoText">Text</label>
            <v-text-field
              id="registerPromoText"
              type="text"
              placeholder="New, exciting & exclusive benefits all in one place"
              solo
              flat
              v-model="configSettings.marketingPopupRegisterPromoText"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>Additional text that goes below the title</i></p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="registerPromoRegisterButtonCta"
              >Registration button CTA</label
            >
            <v-text-field
              id="registerPromoRegisterButtonCta"
              type="text"
              placeholder="Register"
              solo
              flat
              v-model="
                configSettings.marketingPopupRegisterPromoRegisterButtonCta
              "
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half"> </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="registerPromoLoginButtonCta"
              >Login button CTA</label
            >
            <v-text-field
              id="registerPromoLoginButtonCta"
              type="text"
              placeholder="Sign in here"
              solo
              flat
              v-model="configSettings.marketingPopupRegisterPromoLoginButtonCta"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half"> </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="registerPromoLoginButtonText"
              >Login button Additional text</label
            >
            <v-text-field
              id="registerPromoLoginButtonText"
              type="text"
              placeholder="Already a member?"
              solo
              flat
              v-model="
                configSettings.marketingPopupRegisterPromoLoginButtonText
              "
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>This is the text that goes next to the log in option</i></p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupRegisterPromoStyle"
              >Pop-up style</label
            >
            <v-select
              outline
              v-model="configSettings.marketingPopupRegisterPromoStyle"
              :items="[
                { text: 'Dark Mode', value: 'dark' },
                { text: 'Light Mode', value: 'light' },
                { text: 'Club Mode', value: 'club' },
              ]"
              item-value="value"
              item-text="text"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >Defines the background colour of the pop-up and the matching
                text colour</i
              >
            </p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupRegisterPromoButtonStyle"
              >Pop-up button style</label
            >
            <v-select
              outline
              v-model="configSettings.marketingPopupRegisterPromoButtonStyle"
              :items="[
                { text: 'Dark Button', value: 'light' },
                { text: 'Light Button', value: 'dark' },
                { text: 'Club Button', value: 'club' },
              ]"
              item-value="value"
              item-text="text"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >Defines the background colour of the main buttons and the
                matching text colour</i
              >
            </p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupRegisterPromoVideoPageText"
              >Video page text</label
            >
            <v-text-field
              id="marketingPopupRegisterPromoVideoPageText"
              type="text"
              placeholder="A free account is required to watch this video"
              solo
              flat
              v-model="configSettings.marketingPopupRegisterPromoVideoPageText"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>Text that shows on a video page</i></p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label
              class="label"
              for="marketingPopupRegisterPromoMinifiedButtonCta"
              >Minified version register button CTA</label
            >
            <v-text-field
              id="marketingPopupRegisterPromoMinifiedButtonCta"
              type="text"
              placeholder="Register"
              solo
              flat
              v-model="
                configSettings.marketingPopupRegisterPromoMinifiedButtonCta
              "
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >The minified version appears inside a video player when the
                user does not have the correct entitlements to watch a video</i
              >
            </p>
          </v-flex>
        </v-layout>
        <v-layout row class="section-divider">
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupSubMembership"
              >Membership</label
            >
            <v-combobox
              v-if="membershipsLoaded"
              v-model="configSettings.marketingPopupRegisterMembership"
              :items="memberships"
              chips
              solo
              flat
              @input="updateData()"
            ></v-combobox>

            <p v-else>Memberships Loading....</p>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i>Defines the membership applied in the registration pop-up</i>
            </p>
          </v-flex>
        </v-layout>

        <h4 class="subheading mt-0">Subscription Pop-up</h4>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="loginPromoTitle">Title</label>
            <v-text-field
              id="loginPromoTitle"
              type="text"
              placeholder="Subscribe to"
              solo
              flat
              v-model="configSettings.marketingPopupSubPromoTitle"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half"> </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="loginPromoBenefitCta">Benefit CTA</label>
            <v-text-field
              id="loginPromoBenefitCta"
              type="text"
              placeholder="Benefit from"
              solo
              flat
              v-model="configSettings.marketingPopupSubPromoBenefitCta"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i>This is a short text that shows right before the benefit</i>
            </p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="loginPromoMonthSubButtonCta"
              >Package 1 Subscription CTA</label
            >
            <v-text-field
              id="loginPromoMonthSubButtonCta"
              type="text"
              placeholder="Select"
              solo
              flat
              v-model="configSettings.marketingPopupSubPromoFirstSubButtonCta"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >Text that shows in the button inside the first displayed
                package</i
              >
            </p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="loginPromoYearSubButtonCta"
              >Package 2 Subscription CTA</label
            >
            <v-text-field
              id="loginPromoYearSubButtonCta"
              type="text"
              placeholder="Select"
              solo
              flat
              v-model="configSettings.marketingPopupSubPromoSecondSubButtonCta"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >Text that shows in the button inside the second displayed
                package</i
              >
            </p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupSubPromoVideoPageText"
              >Video page text</label
            >
            <v-text-field
              id="marketingPopupSubPromoVideoPageText"
              type="text"
              placeholder="A subscription is required to watch this video"
              solo
              flat
              v-model="configSettings.marketingPopupSubPromoVideoPageText"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>Text that shows on a video page</i></p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupSubPromoMinifiedButtonCta"
              >Minified version button CTA</label
            >
            <v-text-field
              id="marketingPopupSubPromoMinifiedButtonCta"
              type="text"
              placeholder="Subscribe"
              solo
              flat
              v-model="configSettings.marketingPopupSubPromoMinifiedButtonCta"
              @input="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >The minified version appears inside a video player when the
                user does not have the correct entitlements to watch a video</i
              >
            </p>
          </v-flex>
        </v-layout>
        <!-- This is now handled inside packages -->
        <!-- <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="loginPromoLabelText">Golden label text</label>
            <v-text-field id="loginPromoLabelText" type="text" placeholder="Best offer" solo flat v-model="configSettings.marketingPopupSubPromoLabelText" @input="updateData()">
            </v-text-field>
            </v-flex>
          <v-flex mt-4 class="half">
            <p><i>Text inside the golden label that goes in the best offer available</i></p>
          </v-flex>
        </v-layout> -->
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupSubPromoStyle"
              >Pop-up style</label
            >
            <v-select
              outline
              v-model="configSettings.marketingPopupSubPromoStyle"
              :items="[
                { text: 'Dark Mode', value: 'dark' },
                { text: 'Light Mode', value: 'light' },
                { text: 'Club Mode', value: 'club' },
              ]"
              item-value="value"
              item-text="text"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >Defines the background colour of the pop-up and the matching
                text colour</i
              >
            </p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupSubPromoButtonStyle"
              >Pop-up button style</label
            >
            <v-select
              outline
              v-model="configSettings.marketingPopupSubPromoButtonStyle"
              :items="[
                { text: 'Dark Button', value: 'light' },
                { text: 'Light Button', value: 'dark' },
                { text: 'Club Button', value: 'club' },
              ]"
              item-value="value"
              item-text="text"
              @input="updateData()"
            >
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i
                >Defines the background colour of the main buttons and the
                matching text colour</i
              >
            </p>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="marketingPopupSubMembership"
              >Membership</label
            >
            <v-combobox
              v-if="membershipsLoaded"
              v-model="configSettings.marketingPopupSubMembership"
              :items="memberships"
              chips
              solo
              flat
              @input="updateData()"
            ></v-combobox>

            <p v-else>Memberships Loading....</p>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i>Defines the membership applied in the subscription pop-up</i>
            </p>
          </v-flex>
        </v-layout>
      </section>

      <v-btn
        @click="toggleAdvanced()"
        color="accent"
        type="button"
        depressed
        class="advanced-button"
      >
        <span v-if="!toggled">Show Advanced Options</span>
        <span v-else>Hide Advanced Options</span>
      </v-btn>

      <section class="section-container">
        <h3 class="subheading mt-0">Grown Up Mode</h3>
        <v-btn
          @click="toggleJSONEditor()"
          color="accent"
          type="button"
          depressed
          class="advanced-button"
        >
          <span v-if="!grownUpMode">Edit In JSON</span>
          <span v-else>Back To Safety</span>
        </v-btn>
        <template v-if="grownUpMode">
          <v-textarea solo flat rows="40" v-model="configJSON"> </v-textarea>
        </template>
      </section>
    </template>

    <StickyFooter
      :errorMessage="errorMessage"
      :status="status"
      :buttonText="'Save Website Settings'"
      @buttonClicked="saveConfig()"
    />
  </form>
</template>

<script>
import axios from "axios";
import FathomConfiguration from "@/components/Library/Fathom/FathomConfiguration";
import ImageUploader from "@/components/Library/ImageUploader";
import StickyFooter from "@/components/Library/StickyFooter";

export default {
  name: "WebSettings",

  components: {
    FathomConfiguration,
    ImageUploader,
    StickyFooter,
  },

  data: () => ({
    status: "saved",
    errorMessage: "",
    configsLoaded: false,
    advancedMode: false,
    grownUpMode: false,
    modules: null,
    widgets: null,
    superUser: null,
    toggled: false,
    trueFalseSelect: [
      { text: "True", value: true },
      { text: "False", value: false },
    ],
    headerSelect: [
      { text: "Reveal", value: "reveal" },
      { text: "Fixed", value: "fixed" },
      { text: "Sticky", value: "sticky" },
    ],
    takeoverOptions: [
      { text: "Homepage", value: "homepage" },
      { text: "Matchday", value: "matchday" },
    ],
    takeoverStyleOptions: [
      { text: "Banner", value: "banner" },
      { text: "Modal", value: "modal" },
    ],
    takeoverModeOptions: [
      { text: "Light", value: "light" },
      { text: "Club", value: "club" },
      { text: "Dark", value: "dark" },
    ],
    configSettings: {
      //Meta
      metaPageTitleBefore: "",
      metaPageTitleAfter: "",
      metaPageTitle: "",
      metaPageDesc: "",
      newsPageTitle: "",
      newsPageDesc: "",
      videoPageTitle: "",
      videoPageDesc: "",
      //Social
      twitter: "",
      threads: "",
      instagram: "",
      facebook: "",
      tiktok: "",
      snapchat: "",
      youtube: "",
      weibo: "",
      linkedin: "",
      //Images
      errorPageImage: "",
      matchCentreHomeBg: "",
      matchCentreAwayBg: "",
      lockedVideoPlaceholder: "",
      articleCardPlaceholder: "",
      tvSchedulePlaceholder: "",
      socialSharingImage: "",
      playerProfileBackground: "",
      playerProfilePlaceholder: "",
      featuredUpcoming: "",
      featuredResult: "",
      homeKit: "",
      awayKit: "",
      goalkeeperKit: "",
      thirdKit:"",
      //Cookies
      cookieHeadingText: "",
      cookieBodyText: "",
      cookieSlug: "",
      cookieInfoButtonText: "",
      cookieAcceptButtonText: "",
      cookieDeclineButtonText: "",
      //News
      newsPageHeading: "",
      newsPageSubHeading: "",
      //SSO
      loginPortal: "",
      registerUrl: "",
      forgotPasswordUrl: "",
      manageAccountUrl: "",
      loginHeading: "",
      loginBody: "",
      registerHeading: "",
      registerBody: "",
      accountHeading: "",
      videoLoginWarning: "",
      upgradeNoticeHeading: "",
      upgradeNoticeBody: "",
      upgradeNoticeLearnMore: "",
      upgradeNoticePromoImage: "",
      //Stripe
      successPage: "",
      cancelPage: "",
      //Advanced Client
      googleTagManagerID: "",
      ecalLink: "",
      //DeveloperSettings
      googleRecaptchaSiteKey: "",
      googleMapsAPIKey: "",
      stripeAPIKey: "",
      androidLink: "",
      iosLink: "",
      sentryDSN: "",
      sentryEnv: "",
      fathomID: "",
      debugHelp: false,
      //Important Config
      currentSeason: "",
      currentSeasonAlt: "",
      currentSeasonSlug: "",
      firstTeamSlug: "",
      defaultDateFormat: "",
      matchDateFormat: "",
      homeGround: "",
      defaultAuthor: "",
      showDefaultNewsIcon: "",
      shirtIconStyle: "",
      socialSlogan: "",
      signpostExternal: false,
      //Social Sharing
      socialSharingEnabled: false,
      socialSharingTwitterEnabled: false,
      socialSharingTwitterPreText: "",
      socialSharingFacebookEnabled: false,
      socialSharingCopyLinkEnabled: false,
      socialSharingEmailEnabled: false,
      //Copyright
      copyrightNotice: "",
      //New Takeover
      matchdayTakeoverType: "",
      matchdayTakeoverStyle: "",
      matchdayTakeoverMode: "",
      webTakeoverBackground: null,
      appTakeoverBackground: null,
      // Global marketing popup - currently LFC exclusive
      marketingPopupIsActive: false,
      marketingPopupVideoPageIsActive: false,
      marketingPopupDefaultMarketingPopup: "registration",
      marketingPopupRegisterPromoTitle: null,
      marketingPopupRegisterPromoText: null,
      marketingPopupRegisterPromoRegisterButtonCta: null,
      marketingPopupRegisterPromoLoginButtonText: null,
      marketingPopupRegisterPromoLoginButtonCta: null,
      marketingPopupRegisterPromoVideoPageText: null,
      marketingPopupRegisterPromoMinifiedButtonCta: null,
      marketingPopupRegisterPromoStyle: null,
      marketingPopupRegisterPromoButtonStyle: null,
      marketingPopupRegisterMembership: null,
      marketingPopupSubPromoTitle: null,
      marketingPopupSubPromoBenefitCta: null,
      marketingPopupSubPromoFirstSubButtonCta: null,
      marketingPopupSubPromoSecondSubButtonCta: null,
      marketingPopupSubPromoVideoPageText: null,
      marketingPopupSubPromoMinifiedButtonCta: null,
      marketingPopupSubPromoLabelText: null,
      marketingPopupSubPromoStyle: null,
      marketingPopupSubPromoButtonStyle: null,
      marketingPopupSubMembership: null,
    },
    configJSON: null,
    // memberships are currently used in the marketing popup
    memberships: [],
    membershipsLoaded: false,
  }),

  created() {
    this.modules = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
    this.widgets = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/widgets.js");

    if (this.modules.default.LFCExclusive) {
      this.fetchMemberships();
    }
  },

  mounted() {
    this.getConfigs();

    const userCreds = this.$store.state.user.email.split("@");
    if (userCreds[1] === "urbanzoo.io") {
      this.advancedMode = true;
    }
  },

  methods: {
    getConfigs() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${process.env.VUE_APP_CLIENTSETTINGSAPI}/get?settingID=web`, {
            headers: { Authorization: jwt },
          })
          .then((response) => {
            if (response.data.body) {
              this.configSettings = response.data.body.settings
                ? response.data.body.settings
                : {};
            }
            this.configsLoaded = true;
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem retrieving configs", e);
            this.$store.commit("completeLoading");
          });
      });
    },

    saveConfig() {
      this.errorMessage = "";
      this.status = "saving";
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${process.env.VUE_APP_CLIENTSETTINGSAPI}/insert-update`,
          headers: { Authorization: jwt },
          data: {
            settingID: "web",
            settings: JSON.parse(this.configJSON),
          },
        })
          .then((response) => {
            console.log("saved configs", response);
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving website configuration", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving website configuration";
          });
      });
    },

    updateData() {
      this.status = "needsSaving";
      this.configJSON = JSON.stringify(this.configSettings, undefined, 4);
    },

    updateImage(payload, target) {
      this.configSettings[target] = payload.mediaLibraryID;
      this.configSettings[`${target}Key`] = payload.imageKey;
      this.configJSON = JSON.stringify(this.configSettings, undefined, 4);
    },

    toggleAdvanced() {
      if (!this.toggled) {
        this.$getCurrentUserGroups().subscribe(
          (userGroups) => {
            if (userGroups.includes("Administrator")) {
              this.superUser = true;
              this.toggled = true;
            } else {
              this.superUser = false;
              this.toggled = false;
              alert(
                "You are not authorised to edit advanced configuration settings."
              );
            }
          },
          (err) => {
            this.superUser = false;
            console.log(err);
            this.toggled = false;
          }
        );
      } else {
        this.toggled = false;
      }
    },

    toggleJSONEditor() {
      if (
        confirm(
          "Any edits you make in the JSON editor will not show in the fields above and you must save before closing the JSON Editor"
        )
      ) {
        this.grownUpMode = this.grownUpMode === false ? true : false;
        this.configJSON = JSON.stringify(this.configSettings, undefined, 4);
      }
    },

    fetchMemberships() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_VIDEOS_API}/memberships`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("fetch memberships in the widget", response);
            for (const membership of response.data.data) {
              this.memberships.push({
                text: membership.attributes.title,
                value: membership.attributes.membershipID,
              });
            }
            this.membershipsLoaded = true;
          })
          .catch((e) => {
            console.error("Problem getting memberships", e);
          });
      });
    },
  },
};
</script>

<style scoped lang='scss'>
@import "../../styles/settings";

.half {
  width: 50%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}

.input {
  &__textfield {
    &--basic {
      margin-top: 0;
    }
  }
}

.tiny-mce-block {
  margin-bottom: 16px;
}

.quick-link-wrapper {
  padding: 16px;
  font-style: italic;
  background-color: color(warning);
  color: #000;
  border-radius: 8px;
  border: 1px solid color(newHighlightBorder);

  &:hover {
    cursor: pointer;
  }
}

.faded {
  opacity: 0.5;
}

.build-notice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  background-color: #f4f6d2;
  padding: 8px 16px;
  border: 1px solid #d6d8b0;
  border-radius: 8px;

  &__warning {
    margin-bottom: 0;
  }
}

.render-note {
  display: inline-block;
  background-color: #ebebeb;
  margin: 0 0 0 8px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.advanced-button {
  margin-bottom: 30px;
}

.v-select.v-text-field--enclosed {
  /deep/ .v-select__selections {
    padding: 0 !important;
  }
}
</style>
