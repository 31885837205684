<template>
  <section class="config-selector">

    <v-tabs>

      <template v-if="moduleConfig.default.BuildManager">
        <v-tab 
          :ripple="{ class: 'accent--text' }">Website Manager</v-tab>

        <v-tab-item
          px-0
          class="main-body">

          <build-manager :routeToBuild="buildRoute"/>

        </v-tab-item>
      </template>
      
      <template v-if="moduleConfig.default.HomepageManager">
        <v-tab 
          :ripple="{ class: 'accent--text' }">Homepage</v-tab>

        <v-tab-item
          px-0
          class="main-body">

          <homepage-editor />

        </v-tab-item>
      </template>

      <template v-if="moduleConfig.default.PlatformOptions">
        <template v-if="defaultSettings.default.platformSettings.showWeb">
          <v-tab 
            :ripple="{ class: 'accent--text' }">Web Settings</v-tab>

          <v-tab-item
            px-0
            class="main-body">

            <web-settings />

          </v-tab-item>
        </template>
      </template>

    </v-tabs>
  </section>
</template>

<script>
import BuildManager from "@/components/BuildManager/BuildManager"
import HomepageEditor from "@/components/WebsiteManager/HomepageEditor.vue"
import WebSettings from "@/components/PlatformSettings/WebSettings.vue"
export default {
  name: "WebsiteManager",

  components: {
    BuildManager,
    HomepageEditor,
    WebSettings
  },

  data: () => ({
    buildRoute: null,
    moduleConfig: null,
    defaultSettings: null
  }),

  created() {
    this.moduleConfig = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
    this.defaultSettings = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
    if (this.$route.query.routeToBuild) {
      this.buildRoute = this.$route.query.routeToBuild
    }

  },

  mounted() {
    console.warn("route", this.$route)
  }
}
</script>

<style scoped lang="scss">
  .config-selector {
    &__list {
      padding: 0;
      margin: 20px 0 0;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border: 1px solid #67247c;
      border-radius: 6px;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }
  }
</style>
