<template>
  <section class="custom-field-builder" v-if="ready">
    <section v-for="section in buildData" :key="section.model" class="section">
      <h2 class="section__subheading subheading">{{ section.sectionName }}</h2>
      <div
        v-if="section.sectionDesc"
        v-html="section.sectionDesc"
        class="section__desc"
      ></div>

      <div
        v-for="(field, index) in section.fields"
        :key="`${field.model}-${index}`"
      >
        <v-text-field
          v-if="field.type === 'Text'"
          v-model="submitData[section.model][field.model]"
          flat
          filled
          :label="field.label"
          @change="$emit('update', submitData)"
        />

        <v-textarea
          v-if="field.type === 'LongText'"
          :label="field.label"
          outline
          flat
          rows="5"
          v-model="submitData[section.model][field.model]"
          @change="$emit('update', submitData)"
        >
        </v-textarea>

        <v-checkbox
          v-if="field.type === 'Checkbox'"
          v-model="submitData[section.model][field.model]"
          flat
          :label="field.label"
          @change="$emit('update', submitData)"
        />

        <ImageUploader
          v-if="field.type === 'Image'"
          :title="field.label"
          :widthOptionEnabled="false"
          :mediaLibraryID="submitData[section.model][field.model]"
          @update="updateImage($event, section.model, field.model)"
        />
      </div>
    </section>
  </section>
</template>

<script>
import ImageUploader from "@/components/Library/ImageUploader";

export default {
  name: "CustomFieldBuilder",

  components: {
    ImageUploader,
  },

  props: {
    currentData: {
      type: Object,
      required: true,
    },
    buildData: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    ready: false,
    submitData: {},
  }),

  mounted() {
    console.log("Custom Field Builder data", this.buildData);
    console.log("Current values", this.currentData);
    console.log("Submit data", this.submitData);

    for (const section in this.buildData) {
      console.log(
        `Section: ${section}: ${this.buildData[section].sectionName}`
      );
      this.submitData[this.buildData[section].model] = {};

      console.log("Loop through fields", this.buildData[section].fields);

      for (const field in this.buildData[section].fields) {
        console.log("-- Field", this.buildData[section].fields[field].model);

        // console.log('-- Data', this.currentData[this.buildData[section].model][this.buildData[section].fields[field].model])

        this.submitData[this.buildData[section].model][
          this.buildData[section].fields[field].model
        ] = "test";
      }

      console.log("*** Submit data 1", this.submitData);

      this.submitData[this.buildData[section].model] = {
        ...this.currentData[this.buildData[section].model],
      };
    }

    // this.submitData = {
    //   ...this.currentData
    // }

    console.log("*** Submit data 2", this.submitData);

    this.ready = true;
  },

  methods: {
    updateImage(payload, section, field) {
      console.log("updateImage(payload, target)", payload, section, field);
      if (payload.mediaLibraryID) {
        this.submitData[section][field] = payload.mediaLibraryID;
        this.submitData[section][`${field}path`] = payload.imageKey;
        this.$emit("update", this.submitData);
      } else {
        this.submitData[section][field] = null;
        this.submitData[section][`${field}path`] = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.section {
  padding: 20px;
  margin: 0 0 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;

  &__subheading {
    margin-top: 0;
  }

  &__desc {
    margin: 0 0 16px;
  }
}
</style>
